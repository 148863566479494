import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import { BiSolidUser } from "react-icons/bi";
import { GiFemaleVampire } from "react-icons/gi";
import { FcBusinessman, FcBusinesswoman, FcAbout } from "react-icons/fc";
import { GrUserManager } from "react-icons/gr";
import { PiHeadset } from "react-icons/pi";
import { getId } from '../../app/constants';
import Logo from "./sitelogo.png";

const Sidebar = ({ toggle, handleClick }) => {
  const navigation = [
    { name: 'Men', link: '/men', icon: <FcBusinessman /> },
    { name: 'Men Oversized', link: '/men/oversized', icon: <GrUserManager /> },
    { name: 'Women', link: '/women', icon: <FcBusinesswoman /> },
    { name: 'Women Oversized', link: '/women/oversized', icon: <GiFemaleVampire /> },
    { name: 'Help', link: '/contactus', icon: <PiHeadset /> },
    { name: 'About Us', link: '/aboutus', icon: <FcAbout /> },
    { name: 'Custom Design', link: '/aboutus', icon: <FcAbout /> },

  ];

  const id = getId();

  return (
    <>
      <div className={`fixed top-0 left-0 w-[80vw] bg-white h-[100vh] overflow-y-auto z-10 transition-transform duration-300 ease-in-out ${toggle ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="flex items-center justify-between p-4 text-white bg-blue-700 border-b border-gray-200">
          <div className='flex justify-center items-center gap-3'>
            <BiSolidUser className='text-white' size={24} />
            {id ? "Hello User" : <Link to={'/login'} className="text-lg">Login & Signup</Link>}
          </div>
          <button onClick={handleClick} className="text-2xl focus:outline-none">
            <img src={Logo} className='w-8 h-8' alt='logo' />
          </button>
        </div>
        <div className="p-4 flex flex-col justify-between flex-grow">
          {navigation.map((item) => (
            <div key={item.name} className="py-2">
              <Link to={item.link} onClick={handleClick} className="group flex items-center space-x-4">
                <div className="flex justify-start items-end w-full">
                  <p className="text-lg group-hover:font-bold transition duration-300">{item.name}</p>
                </div>
                <span className="text-2xl">{item.icon}</span>
              </Link>
            </div>
          ))}
        </div>
        <div className="p-4 border-t border-gray-200">
          <p className="text-center">Follow Us</p>
          <div className="flex justify-center items-center space-x-6 mt-2">
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-600 hover:text-black">
              <FaInstagram />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-600 hover:text-black">
              <FaFacebook />
            </a>
          </div>
        </div>
      </div>
      {toggle && <div className="fixed inset-0 bg-black bg-opacity-50 z-5" onClick={handleClick}></div>}
    </>
  );
};

export default Sidebar;
