
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../app/constants";
import{getId} from "../../app/constants";
export function createUser(userData) {
  return new Promise(async (resolve) => {
    const response = await fetch(baseUrl+'/auth/signup', {
      method: 'POST',
      body: JSON.stringify(userData),
      headers: { 'content-type': 'application/json' },
    });
    const data = await response.json();
    localStorage.setItem("id",JSON.stringify(data.id))
    resolve({ data });
  });
}

export function loginUser(loginInfo) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(baseUrl+'/auth/login', {
        method: 'POST',
        body: JSON.stringify(loginInfo),
        headers: { 'content-type': 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
        if(data.id){
          localStorage.setItem("id",JSON.stringify(data.id))
        }
     
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject( error );
    }

  });
}

// getiing userdata


export function checkAuth() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(baseUrl+`/auth/check/${getId()}`);
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject( error );
    }

  });
}


export function signOut(userId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = localStorage.clear();
      if (response==undefined) {
        resolve({ data:'success' });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject( error );
    }
  });
}


export function resetPasswordRequest(email,password) {
  return new Promise(async (resolve, reject) => {

    try {
      const response = await fetch(baseUrl+'/users/forgetpassword', {
        method: 'POST',
        body: JSON.stringify({email,password}),
        headers: { 'content-type': 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject( error );
    }

  });
}

export function resetPassword(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(baseUrl+'/auth/reset-password', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'content-type': 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        resolve({ data });
        alert("password update sucessfully");
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject( error );
    }

  });
}
