
import React from 'react';
const ThreeDTShirt = () => {

  return  (
   <div  className=' w-full mt-1'>
  <video  autoPlay muted>
    <source src="https://firebasestorage.googleapis.com/v0/b/shamaim-lifestyle.appspot.com/o/homepage%20video%2FHomepage%20animation%201080p%20(1).mp4?alt=media&token=1933dede-49f5-48d6-80d4-0678dbd37e1b" type="video/mp4" />
  </video>

</div>
  )
};

export default ThreeDTShirt;
