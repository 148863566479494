export const ITEMS_PER_PAGE = 10;
export const baseUrl = "https://helloworld-j376pdtdya-uc.a.run.app";
// export const baseUrl="http://127.0.0.1:5001/shamaim-lifestyle/us-central1/helloWorld";
// export const baseUrl ="http://localhost:8080";
// key: "rzp_test_UanPsB91bqtxk7",


    export function getId(){
    const data=localStorage.getItem("id")
    const id=JSON.parse(data);
    return id;
  }